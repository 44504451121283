import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const NotFound = () => {
  const [timerCount, setTimer] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    let interval = setInterval(() => {
      setTimer((lastTimerCount) => {
        lastTimerCount <= 1 && clearInterval(interval);
        return lastTimerCount - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (timerCount === 0) {
      navigate("/");
    }
  }, [navigate, timerCount]);

  return (
    <div>
      {/* <Navigation props={{title: "Not Found!"}}/> */}
      <div className="not-found">
        <h2>Not Found</h2>
        <p>404 - That page doesn't exist!</p>
        <p>
          You will be redirected to the home page in {timerCount} seconds or{" "}
          <Link to={"/"} title="click here">click here</Link>
          ...
        </p>
      </div>
    </div>
  );
};

export default NotFound;
