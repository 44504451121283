import React from "react";
import { Link } from "react-router-dom";

const Button = (props) => {
  return (
    <Link
      className={[
        "styled",
        props.additionalClasses ? props.additionalClasses : "",
      ].join(" ")}
      to={props.link}
      onClick={props.onClick}
    >
      {props.text}
    </Link>
  );
};

export default Button;
