import React from "react";
import NavigationLink from "./elements/NavigationLink";
import Button from "./elements/Button";

const Navigation = () => {
  const scrollToBottom = (e) => {
    window.scrollTo({
      left: 0,
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <div className="nav-bar">
      <div className="nav-left">
        <h3>Intechlectual</h3>
      </div>
      <div className="nav-right">
        <nav>
          <NavigationLink link="/" text="Home" />
          <NavigationLink link="/about" text="About the Show" />
          <NavigationLink link="/episodes" text="Episodes" />
          <Button onClick={(e) => scrollToBottom()} text="Subscribe" />
        </nav>
      </div>
    </div>
  );
};

export default Navigation;
