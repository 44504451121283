import React from "react";
import { BrowserRouter as BRouter, Routes, Route } from "react-router-dom";

import Home from "./routes/Home";
import About from "./routes/About";
import NotFound from "./routes/NotFound";
import Episodes from "./routes/Episodes";

const Router = () => {
  return (
    <BRouter>
      <Routes>
        <Route path="/" index element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/episodes" element={<Episodes />} />
        {/* <Route path="/episode/:id" element={<Episode />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BRouter>
  );
};

export default Router;
