import React, { useEffect, useState } from "react";
import Parser from "rss-parser";

import Navigation from "../Navigation";
import Footer from "../Footer";
import EpisodeTile from "../elements/EpisodeTile";

const podcastURL = "https://anchor.fm/s/72924150/podcast/rss";

const Episodes = () => {
  const [hasLoadedRSS, setHasLoadedRSS] = useState(Boolean);
  const [hasError, setHasError] = useState(Boolean);
  const [loadedRSS, setLoadedRSS] = useState();

  useEffect(() => {
    let parser = new Parser();

    fetch(podcastURL, {
      mode: "cors",
    })
      .then(response => {
        let process = response.text();
        // console.log(process);
        return process;
      }).then(response => {
        parser.parseString(response, function (err, feed) {
          // console.log(feed);
          // return response
          setLoadedRSS(feed);
        });
      }).catch((error) => {
        setHasError(true);
      })
      .finally(() => {
        setHasLoadedRSS(true);
      });
  }, []);

  return (
    <div>
      <Navigation />
      <section className="all-episodes">
        <div className="inner-wrap">
          <h2>Podcast Episodes</h2>
          {hasLoadedRSS ? (
            hasError ? (
              <h3>Error in RSS Feed :(</h3>
            ) : (
              <div className="episodes-wrapper">
                {loadedRSS.items.map((episode) => {
                  return (
                    <EpisodeTile
                      key={episode.link}
                      imageURL={episode.itunes.image}
                      number={episode.title.split(" ")[1]}
                      link={episode.link}
                      title={episode.title.split("|")[1]}
                    />
                  );
                })}
              </div>
            )
          ) : (
            <h3>Loading episodes...</h3>
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Episodes;
