import React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="inner-wrap">
        <div className="left-column">
          <h4>Follow Us</h4>
          <ul>
            <li>
              <a href="https://instagram.com/intechlectual/">Instagram</a>
            </li>
            <li>
              <a href="https://youtube.com/@intechlectual">YouTube</a>
            </li>
            <li>
              <a href="https://twitter.com/intech_lectual">X (Twitter)</a>
            </li>
          </ul>
        </div>
        <div className="middle-column">
          <h4>Where to Listen?</h4>
          <ul>
            <li>
              <a href="https://podcasters.spotify.com/pod/show/intechlectual">
                Anchor (Web)
              </a>
            </li>
            <li>
              <a href="https://open.spotify.com/show/4WfjpMGMW8EehuKP7qAxQU">
                Spotify
              </a>
            </li>
            <li>
              <a href="https://podcasts.apple.com/us/podcast/intechlectual/id1595163038">
                Apple
              </a>
            </li>
            <li>
              <a href="https://youtube.com/playlist?list=PLZ8rmmu9OF-o_Oo3mb4wXnvFRoWm7qnZW&si=Z4Lj4JCprq6JLaq2">
                YouTube Music
              </a>
            </li>
          </ul>
        </div>
        <div className="right-column">
          <h3>Intechlectual Podcast</h3>
          <p>&copy; Intechlectual Podcast 2024, All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
