import React from "react";

import './App.css';
import Router from "./components/Router";

const App = () => {
  return (
    <div className="wrapper">
      <Router />
    </div>
  );
}

export default App;
