import React from "react";

import Button from "./Button";

const EpisodeTile = (props) => {
  return (
    <div className="episode-tile">
      <img
        className="episode-img"
        src={props.imageURL}
        alt="episode thumbnail"
      />
      <h3>Episode {props.number}</h3>
      <h4>{props.title}</h4>
      <Button
        additionalClasses={["listen-button"]}
        link={props.link}
        text="Listen Now"
      />
    </div>
  );
};

export default EpisodeTile;
