import React, { useLayoutEffect } from "react";

import Navigation from "../Navigation";
import Footer from "../Footer";

const About = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <Navigation />
      <section className="about-hero">
        <div className="inner-wrap">
          <h2>About</h2>
          <p>
            Started by two dudes who are passionate about technology, what's
            happening and what's not happening who thought why not share their
            coversations (and sometimes heated debates) with anyone who wants to
            listen.
          </p>
          <p>
            Discover more about the two hosts, Lui and Emmanuel below in their
            dedicated sections.
          </p>
          <p>
            The podcast will cover anything and everything from technology
            to how-to's, right through to interviews and tech news; join Lui and
            Manny as they navigate through each week serving up and discussing
            their take on recent topics. Kick back and relax and allow yourself
            to be immersed and maybe learn something!
          </p>
        </div>
      </section>
      <section className="about-meet">
        <div className="inner-wrap">
          <h2>Meet the Hosts</h2>
          <div className="hosts">
            <div className="host">
              <div className="meet-left">
                <img alt="Portrait of Lui" src="img/lui.jpeg" />
              </div>
              <div className="meet-right">
                <h3>Lui Crowie</h3>
                <h4>Character Attributes</h4>
                <ul>
                  <li>
                    A no-bars-held character that says what needs to be said
                  </li>
                  <li>
                    Delves into and researches the topic in hand to get the best
                    grasp
                  </li>
                  <li>Isn't afraid to admit he is wrong</li>
                  <li>
                    Adds a lot of 'that's a spicy meet-a-ball' to discussions
                  </li>
                  <li>
                    Listens with intent rather than listening for the sake of it
                  </li>
                  <li>Know's he doesn't know anything</li>
                </ul>
                <h4>Links for Stalking</h4>
                <ul>
                  <li>
                    <a href="https://luicrowie.me">Website/Portfolio</a>
                  </li>
                  <li>
                    <a href="https://instagram.com/engineeringwithlui">Insta</a>
                  </li>
                  <li><a href="https://www.linkedin.com/in/luicrowie">LinkedIn</a></li>
                </ul>
              </div>
            </div>
            <div className="host">
              <div className="meet-left">
                <img alt="Portrait of Emmanuel" src="img/emmanuel.jpeg" />
              </div>
              <div className="meet-right">
                <h3>Emmanuel Simukwayi</h3>
                <h4>Character Attributes</h4>
                <ul>
                  <li>A well mannered gentleman with a brutally honesty approach to life</li>
                  <li>Realist but not a pessimist</li>
                  <li>Lets others get their point across because we're adults</li>
                  <li>Jack of all trades, master of maybe one or two things...</li>
                  <li>Quality over quantity mindset especially when it comes to coffee</li>
                </ul>
                <h4>Links for Stalking</h4>
                <ul>
                  <li>
                    <a href="https://simutography.com">Website/Portfolio</a>
                  </li>
                  <li>
                    <a href="https://instagram.com/emmanuelsimukwayi">Insta</a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/in/emmanuel-simukwayi-697391104">LinkedIn</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default About;
