import React, { useEffect, useState } from "react";
import Parser from "rss-parser";

import Navigation from "../Navigation";
import Footer from "../Footer";
import Button from "../elements/Button";
import EpisodeTile from "../elements/EpisodeTile";

const podcastURL = "https://anchor.fm/s/72924150/podcast/rss";

const Home = () => {
  const [hasLoadedRSS, setHasLoadedRSS] = useState(Boolean);
  const [hasError, setHasError] = useState(Boolean);
  const [loadedRSS, setLoadedRSS] = useState();

  useEffect(() => {
    let parser = new Parser();

    fetch(podcastURL, {
      mode: "cors",
    })
      .then(response => {
        let process = response.text();
        // console.log(process);
        return process;
      }).then(response => {
        parser.parseString(response, function (err, feed) {
          // console.log(feed);
          // return response
          setLoadedRSS(feed);
        });
      }).catch((error) => {
        setHasError(true);
      })
      .finally(() => {
        setHasLoadedRSS(true);
      });
  }, []);

  return (
    <div>
      <Navigation />
      <section className="hero">
        <div className="hero-top">
          <div className="hero-left">
            <h1>
              {hasLoadedRSS ? (
                hasError ? (
                  <i>Error in RSS Feed :(</i>
                ) : (
                  <i>{loadedRSS.items[0].title}</i>
                )
              ) : (
                <i>Looking up episodes...</i>
              )}
            </h1>
            <p>Listen to our latest episode.</p>
            <Button
              additionalClasses={["listenButton"]}
              link={hasLoadedRSS && !hasError ? loadedRSS.items[0].link : ""}
              text="Listen Now"
            />
          </div>
          <div className="hero-right">
            <img
              src={
                hasLoadedRSS && !hasError ? loadedRSS.items[0].itunes.image : ""
              }
              alt="Latest Episode Thumbnail"
            />
          </div>
        </div>
        <div className="hero-bottom">
          <div className="inner-wrap">
            <h3>A technology podcast by engineers.</h3>
          </div>
        </div>
      </section>
      <section className="recent-episodes">
        <div className="inner-wrap">
          <h2>Recent Episodes</h2>
          {hasLoadedRSS ? (
            hasError ? (
              <h3>Error in RSS Feed :(</h3>
            ) : (
              <div className="episodes-wrapper">
                {Array.from({ length: 3 }, (value, index) => index).map((i) => {
                  return (
                    <EpisodeTile
                      key={loadedRSS.items[i].title.split(" ")[1]}
                      imageURL={loadedRSS.items[i].itunes.image}
                      number={loadedRSS.items[i].title.split(" ")[1]}
                      title={loadedRSS.items[i].title.split("|")[1]}
                      link={loadedRSS.items[i].link}
                    />
                  );
                })}
              </div>
            )
          ) : (
            <h3>Loading episodes...</h3>
          )}
        </div>
      </section>
      <section className="meet-hosts">
        <div className="inner-wrap">
          <div className="hosts-left">
            <img
              src={hasLoadedRSS && !hasError ? loadedRSS.image.url : ""}
              alt="Podcast Hosts"
            />
          </div>
          <div className="hosts-right">
            <h3>Meet the Hosts</h3>
            <p>
              Get a deeper dive into the podcast and it's hosts; Lui and
              Emmanuel!
            </p>
            <Button
              // additionalClasses={["listenButton"]}
              link="/about"
              text="Learn More"
            />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
